import React from "react";
import { EImageLayout, ImageWithOverlappedTextBlade } from "@organic-return/foundation-react/src/components/Blades/ImageWithOverlappedTextBlade";

const ImageWithOverlappingText = ({ module }: any) => {
    //get module fields
    const { customFields } = module;

    let imagePosition;
    if (customFields?.imagePosition == "left") {
        imagePosition = EImageLayout.left;
    }
    else {
        imagePosition = EImageLayout.right;
    }

    return (
        <ImageWithOverlappedTextBlade
            classNames="overlapped-image-module"
            backgroundImage={customFields.backgroundImage}
            leadImage={customFields.foregroundImage}
            text={customFields.content}
            title={customFields.title}
            layout={imagePosition}
            callToAction={customFields.callToAction}
            textWrapperClassNames={`${customFields.inverted == "true" ? "inverted" : ""}`}
            htmlIdAttribute={customFields.hTMLIdAttribute}
        />
    )
}

export default ImageWithOverlappingText;