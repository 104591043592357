// All of the Agility Page Module Components that are in use in this site need to be imported into this index file.
// Place Page Modules in allModules array below, passing in a name and the component.
import loadable from "@loadable/component"
import PostsListing from "./PostsListing"
import ImageWithOverlappingText from "./ImageWithOverlappingText"
import Heading from "./Heading"
import TextBlockWithBackgroundImage from "./TextBlockWithBackgroundImage"

const allModules: any[] = [
  { name: "CommunityList", module: "CommunityList" },
  { name: "FilteredListingsSearch", module: "FilteredListingsSearch" },
  { name: "Heading", module: Heading },
  { name: "ImageWithOverlappingText", module: ImageWithOverlappingText },
  { name: "ListingCarousel", module: "ListingCarousel" },
  { name: "ListingEntrySearch", module: "ListingEntrySearch" },
  { name: "LocationMap", module: "LocationMap" },
  { name: "MarketStats", module: "MarketStats" },
  { name: "OfficesGrid", module: "OfficesGrid" },
  { name: "PeopleSearch", module: "PeopleSearch" },
  { name: "PostDetails", module: "PostDetails" },
  { name: "PostsListing", module: PostsListing },
  { name: "RichTextArea", module: "RichTextArea" },
  { name: "TextBlockWithBackgroundImage", module: TextBlockWithBackgroundImage },
]

export const getModule = (moduleName: string) => {
  if (!moduleName) return null
  const obj = allModules.find(
    m => m.name.toLowerCase() === moduleName.toLowerCase()
  )
  if (!obj) return null
  if (typeof obj.module === "string") {
    return loadable(() => import(`./${obj.module}`))
  } else {
    return obj.module
  }
}
