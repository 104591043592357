import React from "react"

import { getModule } from "../../components/agility-pageModules"
import { useSiteContext } from "../../components/common/SiteContext"

// the content zone handles resolving which modules should be rendered on the page
export const ContentZone = ({
  name,
  dynamicPageItem,
  languageCode,
  isMultiLanguage,
  page,
}: any) => {
  const RenderModules = () => {
    if (!page) return null
    const siteContext = useSiteContext()
    let modules = page.zones[name]

    const modulesToRender = modules.map((m: any) => {
      let AgilityModule =
        siteContext.localGetModule && siteContext.localGetModule(m.module)
      !AgilityModule && (AgilityModule = getModule(m.module))

      let props = {
        page,
        dynamicPageItem,
        module: m.item,
        languageCode,
        isMultiLanguage,
      }

      if (AgilityModule) {
        return <AgilityModule key={m.item.contentID} {...props} />
      } else {
        throw new Error(
          `React Component for ${m.module} was not found in the Agility Modules list.`
        )
      }
    })
    return modulesToRender
  }
  return <RenderModules />
}

export default ContentZone
