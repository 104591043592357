import { BackgroundImage } from "@organic-return/foundation-react/src/components/Media"
import React from "react"
import Link from "../../lib/Link"

export const TextBlockWithBackgroundImage = ({ module }: any) => {
    const { customFields } = module

    let contentPosition = "justify-start"
    customFields.contentPosition == "center" && (contentPosition = "justify-center")
    customFields.contentPosition == "bottom" && (contentPosition = "justify-end")

    let blockHeightClass = "h-fit lg:min-h-screen"
    customFields.height == "content" && (blockHeightClass = "h-fit")

    const link = customFields.link?.href && Link({
        to: customFields.link?.href,
        children: customFields.link.text,
        className: "text-button-text bg-button-bg m-0.5 px-5 py-2 align-middle text-center inline-block cursor-pointer px-5 py-2 min-w-[150px] border border-white hover:bg-button-bg-inverted",
    })

    return (
        <BackgroundImage
            src={customFields.backgroundImage?.url}
            className={`text-block-bg-image ${blockHeightClass} flex flex-col ${contentPosition} items-center py-10 inverted`}
        >
            {customFields.title && (
                <h1 className="pt-10">
                    {customFields.title}
                </h1>
            )}
            <div
                className="relative w-full max-w-screen-lg my-3 mx-auto px-5"
                dangerouslySetInnerHTML={{ __html: customFields.content }}>
            </div>
            {link}
        </BackgroundImage>
    )
}

export default TextBlockWithBackgroundImage