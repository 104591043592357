import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { AgilityImage } from "@agility/gatsby-image-agilitycms"
import { Search } from "@organic-return/foundation-react/src/components/Search/Search"

const PostListing = ({ module }: any) => {
  // graphql query for posts data
  const data = useStaticQuery(graphql`
    query {
      posts: allAgilityPost(
        sort: { fields: customFields___date, order: DESC}
      ) {
        nodes {
          contentID
          customFields {
            title
            date(formatString: "MMMM DD, YYYY")
            image {
              url
            }
            category {
              contentid
            }
          }
          path
          linkedContent_agilityCategory: agilityCategory {
            customFields {
              title
            }
          }
        }
      }
    }
  `)

  // get posts
  const posts = data.posts.nodes

  // if there are no posts, display message on frontend
  if (posts.length <= 0) {
    return (
      <div className="mt-44 px-6 flex flex-col items-center justify-center">
        <h1 className="text-3xl text-center font-bold">No posts available.</h1>
        <div className="my-10">
          <Link
            to="/"
            className="px-4 py-3 my-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary transition duration-300"
          >
            Return Home
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div id={module.customFields.hTMLIdAttribute}>
      <h3 className="px-8 md:mt-36 text-center" >{module.customFields.title}</h3>
      <h4 className="italic font-normal text-center" >{module.customFields.subtitle}</h4>
      <Search
        index={posts}
        pageSize={12}
        pagerBottom={true}
        showCount={true}
        className="max-w-screen-2xl mx-auto"
        resultsClassName="gap-3 grid sm:grid-cols-2 ls:grid-cols-3 xl:grid-cols-4"
        renderItem={(post, index) => {
          return (
            <Link to={post.path} key={post.contentID} className="">
              <div>
                <div className="relative h-64">
                  <AgilityImage
                    image={{ ...post.customFields.image }}
                    className="object-cover object-center rounded-t-lg"
                    style={{ height: "100%" }}
                    layout="fullWidth"
                    loading="eager"
                  />
                </div>
                <div className="bg-gray-100 p-8 border-2 border-t-0 rounded-b-lg">
                  <div className="uppercase text-primary-500 text-xs font-bold tracking-widest leading-loose">
                    {post.linkedContent_agilityCategory?.customFields.title ||
                      "Uncategorized"}
                  </div>
                  <div className="border-b-2 border-primary-500 w-8"></div>
                  <h2 className="text-secondary-500 mt-1 font-black text-2xl group-hover:text-primary-500 transition duration-300">
                    {post.customFields.title}
                  </h2>
                </div>
              </div>
            </Link>

          )
        }}
      />
    </div>
  )
}

export default PostListing
