import { EWsrvTransform } from "@organic-return/foundation-react/src/lib/Media"
import {
  BackgroundImage,
  WsrvImage,
} from "@organic-return/foundation-react/src/components/Media"
import React, { CSSProperties } from "react"
import { graphql, useStaticQuery } from "gatsby"

const Heading = ({ module }: any) => {
  const data = useStaticQuery(graphql`
  {    
    allAgilityHeading {
      nodes {
        sizesAndAspectsJSON
        contentID
      }
    }
  }
`)
  // get module fields
  const { customFields } = module

  const agilityHeading = data.allAgilityHeading.nodes.find((node: any) => {
    return node.contentID == module.contentID
  })
  const sizesAndAspectsJSON = agilityHeading?.sizesAndAspectsJSON
  let sizesAndAspect = JSON.parse(sizesAndAspectsJSON ?? "[]")
  if (!sizesAndAspect || sizesAndAspect.length == 0) {
    sizesAndAspect = [
      {
        width: 420,
        aspect: EWsrvTransform.cover3x2,
      },
      {
        media: "min-width:420px",
        width: 640,
        aspect: EWsrvTransform.cover3x2,
      },
      {
        media: "min-width:640px",
        width: 768,
        aspect: EWsrvTransform.cover3x2,
      },
      {
        media: "min-width:768px",
        width: 1024,
        aspect: EWsrvTransform.cover3x2,
      },
      {
        media: "min-width:1024px",
        width: 1280,
        aspect: EWsrvTransform.cover16x9,
      },
      {
        media: "min-width:1280px",
        width: 1440,
        aspect: EWsrvTransform.cover16x9,
      },
    ]
  }

  let bgImageClasses = "relative flex flex-row flex-wrap items-end justify-center w-full"
  let bgImageStyle: CSSProperties = {}
  const height: string = customFields.height
  switch (height) {
    /**
     * Case selected height is "aspect-ratio" we get image's height and width to calculate
     * the image's aspect ratio. We then transform that value to a percentage value and apply
     * that value to BackgroundImage padding top.
     */
    case "aspect-ratio":
      if (customFields.backgroundImage?.pixelHeight && customFields.backgroundImage?.pixelWidth) {
        const width = customFields.backgroundImage.pixelWidth
        const height = customFields.backgroundImage.pixelHeight
        const paddingTop = (height / width) * 100
        bgImageStyle = {
          paddingTop: `${paddingTop}%`
        }
      }
      break
    /**
     * Case selected height is "screen" we set the BackgroundImage height to screen size. This will
     * also be the behavior when no height is selected.
     */
    case "screen":
    case undefined:
    case null:
    case "":
      bgImageClasses = `${bgImageClasses} h-screen`
      break
    /**
     * Case a fixed height value is selected, we set the BackgroundImage height with that value.
     */
    default:
      bgImageStyle = {
        height: height
      }
      break
  }

  return (
    <>
      {customFields.backgroundImage?.url && (
        <>
          <BackgroundImage
            src={customFields.backgroundImage?.url}
            className={`heading-module ${bgImageClasses}`}
            sizesAndAspect={sizesAndAspect}
            style={bgImageStyle}
          >
            {!customFields.leadImage?.url &&
              <div className="absolute max-h-full heading-overlay">
                {customFields.title && (
                  <h1 className={"px-8"}>
                    {customFields.title && customFields.title}
                  </h1>
                )}
                {customFields.subTitle && (
                  <h3 className="italic font-normal text-center">
                    {customFields.subTitle}
                  </h3>
                )}
              </div>
            }
          </BackgroundImage>
        </>
      )}
      {!customFields.leadImage?.url && !customFields.backgroundImage?.url && (
        <div className="heading-module">
          {customFields.title && (
            <h1 className="px-8 md:mt-28" >
              {customFields.title && customFields.title}
            </h1>
          )}
          {customFields.subTitle && (
            <h3 className="mb-4 italic font-normal text-center">
              {customFields.subTitle}
            </h3>
          )}
        </div>
      )}
      {customFields.leadImage?.url && (
        <div className="heading-module relative max-w-screen-xl mx-auto">
          <WsrvImage
            className="-mt-20 sm:-mt-48 block mx-auto max-w-full h-auto"
            urlParams={{
              originURL: customFields.leadImage.url,
              preset: EWsrvTransform.limit,
            }}
            lazy={false}
            imgProps={{
              width: customFields.leadImage.width,
              height: customFields.leadImage.height,
            }}
          />
          <h1
            className={`tracking-widest text-center ${customFields.subTitle ? "mb-0 pb-0" : ""
              }`}
            style={{ textShadow: "2px 2px 4px white" }}
          >
            {customFields.title}
          </h1>
          {customFields.subTitle && (
            <h3 className="text-gray-400  italic font-normal text-center">
              {customFields.subTitle}
            </h3>
          )}
        </div>
      )}
    </>
  )
}

export default Heading
